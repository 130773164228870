import { StatusEnum } from '@prodelio/modules/tasks/types/Status.enum';
import { GetSubtasksRequest } from '@prodelio/modules/tasks/features/list-subtasks/GetSubtasks.request';
import { TaskList } from '@prodelio/components/task-list/TaskList';
import {
  TaskSortOptions,
  useSelectTasksV2,
} from '@prodelio/modules/tasks/state/selectors/selectTasks';

interface ListSubtasksProps {
  parentTaskId: string;
}

export const ListSubtasks = ({ parentTaskId }: ListSubtasksProps) => {
  const request = new GetSubtasksRequest({ parentTaskId });
  const sort = [TaskSortOptions.MANUAL];

  const filters = {
    status: StatusEnum.TODO,
    parentTaskId: parentTaskId,
  };

  const tasks = useSelectTasksV2(filters, sort);

  return (
    <TaskList
      filters={filters}
      sort={sort}
      initialTasks={tasks}
      request={request}
    />
  );
};
