import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '@prodelio/hooks/auth/useAuth';
import React, { useEffect } from 'react';
import { AuthInterceptor } from '@prodelio/config/api/Auth.interceptor';

interface Props {
  defaultRedirect: string;
}

export const ProtectedRoutes = ({ defaultRedirect }: Props) => {
  const navigate = useNavigate();
  const { checkAuth } = useAuth();

  useEffect(() => {
    checkAuth().then((isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        navigate(defaultRedirect);
      }
    });
  }, []);

  return (
    <>
      <AuthInterceptor />
      <Outlet />
    </>
  );
};
