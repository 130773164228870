import { Box, Stack } from '@chakra-ui/react';
import { Kanban } from '@prodelio/components/kanban/Kanban';
import {
  AppViews,
  TaskViewEnum,
} from '@prodelio/config/state/app-config/AppConfig';
import { useViewConfig } from '@prodelio/config/state/app-config/selectors/useViewConfig';
import { useGetCurrentView } from '@prodelio/hooks/route-props/useGetCurrentView';
import { arrayMove } from '@dnd-kit/sortable';
import {
  Active,
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  Over,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { isSameDay } from 'date-fns';
import { EditTaskRequest } from '@prodelio/modules/tasks/features/edit-task/request/EditTask.request';
import { SectionTaskList } from '@prodelio/components/section-task-list/SectionTaskList';
import { useCallback, useEffect, useState } from 'react';
import { TaskComponent } from '@prodelio/components/task/Task.component';
import { isArrayEqual } from '@prodelio/utils/isArrayEqual';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';
import { getTask } from '@prodelio/modules/tasks/state/selectors/getTask';
import {
  SyncStatus,
  Task,
  TaskSort,
} from '@prodelio/modules/tasks/state/types/Task';
import {
  TaskFilters,
  TaskSortOptions,
} from '@prodelio/modules/tasks/state/selectors/selectTasks';
import { TaskSyncronizer } from '@prodelio/modules/tasks/services/task-synchronizer/TaskSynchronizer';

export interface TaskViewSection {
  key: string;
  header: string;
  tasks: Task[];
  filters: Partial<TaskFilters>;
  sort: TaskSortOptions[];
  displayHeader?: boolean;
  droppable?: boolean;
  quickCreateTasks?: boolean;
}

interface TaskViewProps {
  viewId?: string;
  children?: JSX.Element | JSX.Element[];
  canChange?: boolean;
  taskFilters?: Partial<TaskFilters>;
  sections?: TaskViewSection[];
}

export const TaskView = ({
  canChange = false,
  sections,
  viewId = '',
}: TaskViewProps) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const activeTask: Task | undefined = activeId ? getTask(activeId) : undefined;

  const editTask = useTaskStore((store) => store.edit);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 10,
      },
    })
  );

  const currentView = useGetCurrentView();
  const { taskView } = useViewConfig(currentView ? currentView : '');

  const renderSections =
    sections?.map(
      ({
        header,
        tasks,
        key,
        filters,
        sort,
        displayHeader = true,
        quickCreateTasks = true,
      }) => {
        return (
          <SectionTaskList
            header={header}
            key={key}
            sectionId={key}
            tasks={tasks}
            TaskListProps={{ filters, sort, quickCreateTasks }}
            displayHeader={displayHeader}
          />
        );
      }
    ) ?? [];

  const ListView = (
    <Stack direction="column" gap={50} pb={70}>
      {renderSections}
    </Stack>
  );

  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    setActiveId(active.id as string);
  }, []);

  const isSectionUndroppable = (over: Over) => {
    const overSection = sections?.find(({ tasks }) =>
      tasks.find(({ id }) => id === over.id)
    );

    return overSection && overSection.droppable === false;
  };

  const getDroppableDate = (
    doppableContainer: Over | Active
  ): Date | undefined => {
    if (Number.isNaN(Number(doppableContainer.id))) {
      return doppableContainer.data.current?.date;
    }

    return new Date(Number(doppableContainer.id));
  };

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      setActiveId(null);
      if (!over?.id) return;

      if (isSectionUndroppable(over)) {
        return;
      }

      const overDate = getDroppableDate(over);

      const tasks = sections?.flatMap(({ tasks }) => tasks) ?? [];
      const oldIndex = tasks.findIndex(({ id }) => active.id === id);
      const newIndex = tasks.findIndex(({ id }) => over.id === id);
      const modifiedContainer = arrayMove(tasks, oldIndex, newIndex);

      const tasksToSync = modifiedContainer.map((task, index) => {
        const sort = {
          ...((task.sort || {}) as TaskSort),
          [(currentView as AppViews) + viewId]: index,
        };

        if (task.id === active.id) {
          return {
            ...task,
            syncStatus: SyncStatus.EDITED,
            dueDate: canChange
              ? overDate?.toISOString() ?? task.dueDate
              : task.dueDate,
            updatedAt: new Date().toISOString(),
            sort,
          };
        }

        return {
          ...task,
          syncStatus: SyncStatus.EDITED,
          updatedAt: new Date().toISOString(),
          sort: sort,
        };
      });

      const taskSyncronizer = new TaskSyncronizer();
      taskSyncronizer.syncTasks(tasksToSync as Task[]);
    },
    [sections]
  );

  const handleDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      if (!active || !over || !canChange || !activeTask) {
        return;
      }

      if (isSectionUndroppable(over)) {
        return;
      }

      const activeDate = getDroppableDate(active);
      const overDate = getDroppableDate(over);
      if (!activeDate || !overDate || isSameDay(activeDate, overDate)) {
        return;
      }

      const editRequest = new EditTaskRequest(
        activeTask.id,
        {
          ...activeTask,
          dueDate: overDate,
          tags: activeTask.tags.join(';'),
        },
        false
      );
      editTask(editRequest, {});
    },
    [activeId]
  );

  const KanbanView = <Kanban>{renderSections}</Kanban>;

  return (
    <Box pb={70}>
      <DndContext
        collisionDetection={closestCenter}
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
      >
        {taskView === TaskViewEnum.LIST || !canChange ? ListView : KanbanView}
        <DragOverlay>
          {activeTask && (
            <Box opacity={0.7}>
              <TaskComponent
                id={activeTask.id}
                name={activeTask.title}
                priority={activeTask.priority}
                dueDate={
                  activeTask.dueDate ? new Date(activeTask.dueDate) : null
                }
                tags={activeTask.tags}
                timeEstimated={activeTask.timeEstimation}
                timeTracked={activeTask.timeTracked}
                status={activeTask.status}
              />
            </Box>
          )}
        </DragOverlay>
      </DndContext>
    </Box>
  );
};
