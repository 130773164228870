import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useValidation } from '@prodelio/hooks/form/useValidation';
import { useForm } from '@prodelio/hooks/form/useForm';
import { PriorityConverter } from '@prodelio/modules/tasks/types/Priority.converter';
import { DatepickerComponent } from '@prodelio/components/datepicker/Datepicker.component';
import { EditTaskRequest } from '@prodelio/modules/tasks/features/edit-task/request/EditTask.request';
import { timeParser } from '@prodelio/modules/tasks/time.parser';
import { TaskConstants } from '@prodelio/modules/tasks/Task.constants';
import { TaskEvents } from '@prodelio/modules/tasks/Task.events';
import { DeleteTask } from '@prodelio/modules/tasks/delete-task/DeleteTask';
import { MsToTimeParser } from '@prodelio/modules/tasks/MsToTime.parser';
import { format, startOfDay } from 'date-fns';
import { store } from '@prodelio/config/state/store';
import { useEventDispatch } from '@prodelio/event-system/hooks/useEventDispatch';
import { Select } from '@prodelio/components/form/select/Select';
import { useProjects } from '@prodelio/config/state/projects/selectors/getProjects';
import { Shortcut } from '@prodelio/components/shortcut/Shortcut.component';
import { useKeyShortcut } from '@prodelio/modules/common/hooks/useKeyShortcut';
import { useEvent } from '@prodelio/event-system/hooks/useEvent';
import { ListSubtasks } from '@prodelio/modules/tasks/features/list-subtasks/ListSubtasks';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';

interface EditTaskProps {
  id: string;
}

export const EditTask = ({ id }: EditTaskProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const task = useTaskStore((state) =>
    state.tasks.find((task) => task.id === id)
  );
  const project = store((state) =>
    state.projects.find((project) => project.id === task?.projectId)
  );

  const initialState = useMemo(
    () => ({
      title: {
        value: task ? task.title : '',
      },
      timeEstimation: {
        value: task ? MsToTimeParser(task.timeEstimation) : 0,
      },
      timeTracked: {
        value: task ? MsToTimeParser(task.timeTracked) : 0,
      },
      priority: {
        value: task ? task.priority : 0,
      },
      dueDate: {
        value:
          task && task.dueDate ? startOfDay(new Date(task.dueDate)) : undefined,
      },
      project: {
        value: task && task.projectId ? task.projectId : '',
      },
      tags: {
        value: task && task.tags && task.tags[0] ? task.tags[0] : '',
      },
      assignedTo: {
        value: task && task.assignedTo ? task.assignedTo : '',
      },
    }),
    [task]
  );
  const { formValues, onChange, resetForm } = useForm(initialState, true);
  const { formErrors, validate, resetFormErrors } = useValidation({
    title: {
      validators: ['empty'],
    },
    timeEstimation: {
      validators: ['pattern', 'empty'],
      options: {
        pattern: TaskConstants.TIME_PATTERN,
      },
    },
    timeTracked: {
      validators: ['pattern'],
      options: {
        pattern: TaskConstants.TIME_PATTERN,
      },
    },
  });

  const projects = useProjects(undefined, [
    { orderBy: 'title', sortType: 'ASC' },
  ]);

  const editTaskAction = useTaskStore((state) => state.edit);

  const dispatch = useEventDispatch();

  const handleCloseModal = () => {
    resetForm();
    resetFormErrors();
    dispatch(TaskEvents.CLOSE_EDIT_TASK_MODAL);
  };

  const handleSubmit = () => {
    if (!validate(formValues)) {
      return;
    }

    setIsLoading(true);

    const projectId = formValues.project.value;

    const dueDate = formValues.dueDate.value
      ? format(formValues.dueDate.value, 'yyyy-MM-dd HH:mm:ss')
      : formValues.dueDate.value;
    const editTaskRequest = new EditTaskRequest(id, {
      title: formValues.title.value,
      dueDate: dueDate,
      timeEstimation: timeParser(formValues.timeEstimation.value),
      timeTracked: timeParser(formValues.timeTracked.value),
      priority: +formValues.priority.value,
      projectId: projectId?.length ? projectId : null,
      tags:
        formValues.tags.value || formValues.tags.value.length
          ? [formValues.tags.value].join(';')
          : '',
      assignedTo: formValues.assignedTo.value ?? '',
    });
    editTaskAction(editTaskRequest, {});

    setIsLoading(false);
    handleCloseModal();
  };

  useEvent(TaskEvents.EDITED_TASK, handleSubmit);

  useKeyShortcut({
    shortcuts: {
      'CTRL+SHIFT+Enter': () => {
        dispatch(TaskEvents.EDITED_TASK);
      },
    },
  });

  const renderActions = () => {
    const editShortcut = <Shortcut>CTRL + SHIFT + Enter</Shortcut>;

    const actions = () => (
      <>
        <Tooltip label={editShortcut}>
          <Button
            isLoading={isLoading}
            onClick={handleSubmit}
            variant="primary"
            loadingText="Editing..."
          >
            Edit
          </Button>
        </Tooltip>
        <DeleteTask id={id} />
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancel
        </Button>
      </>
    );

    return (
      <HStack pt="26px" pb="16px" spacing="20px">
        {actions()}
      </HStack>
    );
  };

  const displayAssignToSelect = project && project.sharedWith.length > 0;

  const renderBody = () => (
    <>
      <FormControl isInvalid={!!formErrors.title}>
        <FormLabel>Task name</FormLabel>
        <Input
          variant="filled"
          type="text"
          name="title"
          value={formValues.title.value}
          onChange={onChange}
        />
        {formErrors.title && (
          <FormErrorMessage>{formErrors.title}</FormErrorMessage>
        )}
      </FormControl>
      {displayAssignToSelect && (
        <FormControl isInvalid={!!formErrors.assignedTo}>
          <FormLabel>Assigned to</FormLabel>
          <Select
            name="assignedTo"
            selected={formValues.assignedTo.value}
            onChange={onChange}
            options={[
              { value: '', label: 'Not assigned' },
              ...project.sharedWith
                .filter(({ userId }) => userId)
                .map(({ userId, email }) => ({ value: userId, label: email })),
            ]}
          />
          {formErrors.assignedTo && (
            <FormErrorMessage>{formErrors.assignedTo}</FormErrorMessage>
          )}
        </FormControl>
      )}
      <HStack gap="25px">
        <FormControl isInvalid={!!formErrors.timeTracked}>
          <FormLabel>Time tracked</FormLabel>
          <Input
            variant="filled"
            type="text"
            name="timeTracked"
            value={formValues.timeTracked.value}
            onChange={onChange}
          />
          {formErrors.timeTracked && (
            <FormErrorMessage>{formErrors.timeTracked}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!formErrors.timeEstimation}>
          <FormLabel>Time estimation</FormLabel>
          <Input
            variant="filled"
            type="text"
            name="timeEstimation"
            value={formValues.timeEstimation.value}
            onChange={onChange}
          />
          {formErrors.timeEstimation && (
            <FormErrorMessage>{formErrors.timeEstimation}</FormErrorMessage>
          )}
        </FormControl>
      </HStack>
      <HStack gap="25px">
        <FormControl>
          <FormLabel>Project</FormLabel>
          <Select
            name="project"
            selected={formValues.project.value}
            onChange={onChange}
            options={[
              { value: '', label: 'No Project Selected' },
              ...projects.map(({ id, title }) => ({
                value: id,
                label: title,
              })),
            ]}
          />
          {formErrors.priority && (
            <FormErrorMessage>{formErrors.priority}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Tags</FormLabel>
          <Select
            name="tags"
            selected={formValues.tags.value}
            onChange={onChange}
            options={[
              { value: '', label: 'No Tags' },
              { value: 'next_actions', label: 'Next Actions' },
              { value: 'maybe', label: 'Maybe' },
            ]}
          />
          {formErrors.tags && (
            <FormErrorMessage>{formErrors.tags}</FormErrorMessage>
          )}
        </FormControl>
      </HStack>
      <HStack gap="25px">
        <FormControl isInvalid={!!formErrors.priority}>
          <FormLabel>Priority</FormLabel>
          <Select
            name="priority"
            selected={String(formValues.priority.value)}
            onChange={onChange}
            options={new Array(7).fill(null).map((_, index) => ({
              value: String(index),
              label:
                index === 0 ? 'No Priority Selected' : PriorityConverter[index],
            }))}
          />
          {formErrors.priority && (
            <FormErrorMessage>{formErrors.priority}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Due date</FormLabel>
          <DatepickerComponent
            inputProps={{
              variant: 'filled',
            }}
            name="dueDate"
            value={formValues.dueDate.value}
            onChange={onChange}
          />
        </FormControl>
      </HStack>
    </>
  );

  return (
    <>
      <VStack spacing={6} alignItems="left">
        {renderBody()}
        {renderActions()}
        <VStack spacing="20px" alignItems="stretch">
          <Heading size="sm" as="h4">
            Subtasks
          </Heading>
          <ListSubtasks parentTaskId={id} />
        </VStack>
      </VStack>
    </>
  );
};
