import { useEvent } from '@prodelio/event-system/hooks/useEvent';
import { GetTasksByProjectRequest } from '@prodelio/modules/tasks/features/list-tasks-by-project/requests/GetTasksByProject.request';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectEvents } from '@prodelio/modules/projects/Project.events';
import { TaskView } from '@prodelio/components/task-view/TaskView';
import { useEffect } from 'react';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';
import {
  TaskSortOptions,
  useSelectTasksV2,
} from '@prodelio/modules/tasks/state/selectors/selectTasks';

export const ListTasksByProject = () => {
  const { projectId } = useParams<string>();
  const navigate = useNavigate();

  const request = new GetTasksByProjectRequest(projectId || '');

  const filters = {
    projectId,
    parentTaskId: null,
  };

  useEvent(
    [ProjectEvents.DELETED_PROJECT, ProjectEvents.ARCHIVED_PROJECT],
    (deleteProjectId: string) => {
      if (projectId === deleteProjectId) {
        navigate('/app/inbox');
      }
    }
  );

  const syncTasks = useTaskStore((state) => state.syncTasks);

  useEffect(() => {
    syncTasks(request, filters);
  }, [projectId]);

  const sort = [TaskSortOptions.MANUAL];

  const tasks = useSelectTasksV2(filters, sort);

  const sections = [
    {
      key: 'ListTasksByProject' + projectId,
      header: '',
      tasks,
      filters,
      sort,
      displayHeader: false,
    },
  ];

  return (
    <TaskView taskFilters={filters} sections={sections} viewId={projectId} />
  );
};
