import { Box, Heading } from '@chakra-ui/react';
import {
  TaskList,
  TaskListProps,
} from '@prodelio/components/task-list/TaskList';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import { Task } from '@prodelio/modules/tasks/state/types/Task';

interface SectionTaskListProps {
  displayHeader?: boolean;
  header: string;
  TaskListProps: TaskListProps;
  tasks?: Task[];
  sectionId: string;
}

export const SectionTaskList = ({
  displayHeader = true,
  header,
  TaskListProps,
  tasks,
  sectionId,
}: SectionTaskListProps) => {
  const { setNodeRef } = useDroppable({
    id: sectionId,
    data: {
      date: TaskListProps.filters.date,
    },
  });

  return (
    <Box position="relative" ref={setNodeRef}>
      {displayHeader && (
        <Heading size="md" mb={6}>
          {header}
        </Heading>
      )}
      <TaskList {...TaskListProps} initialTasks={tasks} areDraggable>
        {(taskElements, tasks) => (
          <SortableContext
            items={tasks.map(({ id }) => id)}
            strategy={verticalListSortingStrategy}
          >
            {tasks.length === 0 && (
              <Box position="absolute" height={200} width="100%" />
            )}
            {taskElements}
          </SortableContext>
        )}
      </TaskList>
    </Box>
  );
};
