import create from 'zustand';
import { persist } from 'zustand/middleware';

export enum AppViews {
  CURRENT_WEEK = 'CURRENT_WEEK',
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  NEXT_14_DAYS = 'NEXT_14_DAYS',
  INBOX = 'INBOX',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  PROJECT = 'PROJECT',
}

export enum TaskViewEnum {
  KANBAN = 'KANBAN',
  LIST = 'LIST',
}

export const AppRoutes: { [key: string]: any } = {
  [AppViews.CURRENT_WEEK]: {
    pathname: '/app/week/current',
    sortedTaskSections: 7,
  },
  [AppViews.NEXT_7_DAYS]: {
    pathname: '/app/next/7/days',
    sortedTaskSections: 7,
  },
  [AppViews.NEXT_14_DAYS]: {
    pathname: '/app/next/14/days',
    sortedTaskSections: 14,
  },
  [AppViews.INBOX]: {
    pathname: '/app/inbox',
    sortedTaskSections: 1,
  },
  [AppViews.TODAY]: {
    pathname: '/app/today',
    sortedTaskSections: 1,
  },
  [AppViews.TOMORROW]: {
    pathname: '/app/tomorrow',
    sortedTaskSections: 1,
  },
  [AppViews.PROJECT]: {
    pathname: '/app/project',
    sortedTaskSections: 1,
  },
};

interface ViewConfig {
  taskView: TaskViewEnum;
  sortedTasks: string[] | string[][];
}

const commonViewConfig: ViewConfig = {
  taskView: TaskViewEnum.LIST,
  sortedTasks: [],
};

const viewsConfig = Object.entries(AppRoutes).reduce(
  (currentValue, [view, config]) => ({
    ...currentValue,
    [view]: {
      ...commonViewConfig,
    },
  }),
  {}
);

const updateViewConfigUtil = (
  set: any,
  view: AppViews,
  config: Partial<ViewConfig>
) => {
  set((state: AppConfigSlice) => {
    const {
      config: { views, ...restConfig },
    } = state;

    return {
      ...state,
      config: {
        ...restConfig,
        views: {
          ...views,
          [view]: {
            ...views[view],
            ...config,
          },
        },
      },
    };
  });
};

export const useAppConfigStore = create<
  AppConfigSlice,
  [['zustand/persist', AppConfigSlice]]
>(
  persist(
    (set, get) => ({
      config: {
        global: {
          views: commonViewConfig,
        },
        views: viewsConfig,
      },
      changeViewConfig: (view: AppViews, config: Partial<ViewConfig>) => {
        set((state: AppConfigSlice) => {
          const {
            config: { views, ...restConfig },
          } = state;

          return {
            ...state,
            config: {
              ...restConfig,
              views: {
                ...views,
                [view]: {
                  ...views[view],
                  ...config,
                },
              },
            },
          };
        });
      },
      initSortedTasks: (taskIds: string[], currentView: string) => {
        const sortedTasks = get().config.views[currentView]?.sortedTasks ?? [];

        const maxSortedTasksSectionLength =
          AppRoutes[currentView]?.sortedTaskSections;
        if (
          !maxSortedTasksSectionLength ||
          sortedTasks.length === maxSortedTasksSectionLength
        )
          return;

        updateViewConfigUtil(set, currentView as AppViews, {
          sortedTasks: [...(sortedTasks as string[][]), taskIds],
        });
      },
    }),
    {
      name: 'catd-config',
      getStorage: () => localStorage,
    }
  )
);

export interface AppConfigSlice {
  config: {
    global: {
      views: ViewConfig;
    };
    views: {
      [key: string]: ViewConfig;
    };
  };
  changeViewConfig: (view: AppViews, config: Partial<ViewConfig>) => void;
  initSortedTasks: (taskIds: string[], currentView: string) => void;
}
