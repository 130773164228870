import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';
import { endOfDay, startOfTomorrow } from 'date-fns';

export class GetTomorrowTasksRequest extends Request {
  public method: ApiMethods = ApiMethods.GET;
  public url = '/tasks';

  constructor() {
    super();

    const day: Date = startOfTomorrow();
    this.addFilters({
      dueDate__range: `${day.toISOString()},${endOfDay(day).toISOString()}`,
    });
  }
}
