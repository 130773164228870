import { StatusEnum } from '@prodelio/modules/tasks/types/Status.enum';
import { isAfter, isBefore, isSameDay, parseISO, startOfDay } from 'date-fns';
import { getCurrentView } from '@prodelio/hooks/route-props/useGetCurrentView';
import { useParams } from 'react-router-dom';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';
import { TaskState } from '@prodelio/modules/tasks/state/TaskStore';
import { Task } from '@prodelio/modules/tasks/state/types/Task';

export interface TaskFilters {
  idIn: string[];
  status: StatusEnum;
  startDate: Date;
  endDate: Date;
  date: Date;
  projectId: string | null | undefined;
  createdAt: Date | null;
  parentTaskId: string | null | undefined;
}

const filterFunctions: { [key: string]: (task: Task, value: any) => boolean } =
  {
    idIn: (task: Task, idIn: string[]) => idIn.indexOf(task.id) > -1,
    status: (task: Task, status: StatusEnum) => task.status === status,
    startDate: (task: Task, startDate: Date) =>
      !!task.dueDate && isAfter(new Date(task.dueDate), startDate),
    endDate: (task: Task, endDate: Date) =>
      !!task.dueDate && isBefore(new Date(task.dueDate), endDate),
    date: (task: Task, date: Date) => isSameDay(new Date(task.dueDate), date),
    projectId: (task: Task, projectId: string) => task.projectId === projectId,
    parentTaskId: (task: Task, parentTaskId: string) =>
      task.parentTaskId === parentTaskId,
    createdAt: (task: Task, createdAt: Date | null) =>
      task.createdAt === createdAt,
    tags: (task: Task, tags: string[]) =>
      tags.every((tag) => !!task.tags && task.tags.includes(tag)),
  };

export enum TaskSortOptions {
  DUE_DATE = 'dueDate',
  PRIORITY = 'priority',
  MANUAL = 'manual',
}

export const sortFunctions = {
  [TaskSortOptions.DUE_DATE]: (
    { dueDate: dateA }: Task,
    { dueDate: dateB }: Task
  ) => {
    const parsedDateA = startOfDay(parseISO(dateA));
    const parsedDateB = startOfDay(parseISO(dateB));

    if (isAfter(parsedDateA, parsedDateB)) {
      return 1;
    } else if (isBefore(parsedDateA, parsedDateB)) {
      return -1;
    }

    return 0;
  },
  [TaskSortOptions.PRIORITY]: (
    { priority: priorityA }: Task,
    { priority: priorityB }: Task
  ) => {
    if (priorityA === 0) {
      return 1;
    } else if (priorityB === 0) {
      return -1;
    }

    return priorityA - priorityB;
  },
  [TaskSortOptions.MANUAL]: (
    a: Task,
    b: Task,
    options: Record<string, any>
  ) => {
    const currentView = getCurrentView();
    const sortKey = options.projectId
      ? currentView + options.projectId
      : currentView;

    if (!a.sort && !b.sort) return 0;

    if (a.sort && b.sort && !a.sort[sortKey] && !b.sort[sortKey]) return 0;

    return (
      (a.sort && a.sort[sortKey] ? a.sort[sortKey] + 1 : 0) -
      (b.sort && b.sort[sortKey] ? b.sort[sortKey] + 1 : 0)
    );
  },
};

const filterFunc = (
  filtersToApply: Partial<TaskFilters>,
  sortBy: TaskSortOptions[],
  options: Record<string, string | undefined | null> = {}
) => {
  return (state: TaskState) => {
    const tasks = state.tasks.filter((task) =>
      Object.entries(filtersToApply!).every(([key, value]) =>
        filterFunctions[key](task, value)
      )
    );

    for (const sortByElement of sortBy) {
      tasks.sort((a, b) => sortFunctions[sortByElement](a, b, options));
    }

    return tasks;
  };
};

export const useSelectTasksV2 = (
  filters: Partial<TaskFilters> = {},
  sortBy: TaskSortOptions[] = [
    TaskSortOptions.PRIORITY,
    TaskSortOptions.DUE_DATE,
  ]
): Task[] => {
  const { projectId } = useParams();

  const filtersToApply = {
    status: StatusEnum.TODO,
    ...filters,
  };

  const uniqueTaskIds = new Set();

  const taskSelector = filterFunc(filtersToApply, sortBy, { projectId });
  const tasks = useTaskStore(taskSelector);

  return tasks.filter(({ id }) => {
    if (uniqueTaskIds.has(id)) {
      return false;
    }

    uniqueTaskIds.add(id);

    return true;
  });
};

export const selectTasksV2 = (
  filters: Partial<TaskFilters> = {},
  sortBy: TaskSortOptions[] = [
    TaskSortOptions.PRIORITY,
    TaskSortOptions.DUE_DATE,
  ]
): Task[] => {
  const filtersToApply = {
    status: StatusEnum.TODO,
    ...filters,
  };

  return filterFunc(filtersToApply, sortBy)(useTaskStore.getState());
};
