import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';
import { endOfDay, startOfDay } from 'date-fns';

export class GetTodayTasksRequest extends Request {
  public method: ApiMethods = ApiMethods.GET;
  public url = '/tasks';

  constructor() {
    super();

    const today = new Date();
    this.addFilters({
      dueDate__range: `${startOfDay(today).toISOString()},${endOfDay(
        today
      ).toISOString()}`,
    });
  }
}
