import {
  Box,
  Checkbox,
  Heading,
  HStack,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { format, isBefore, isSameDay, startOfToday } from 'date-fns';
import { PriorityConverter } from '@prodelio/modules/tasks/types/Priority.converter';
import { StatusEnum } from '@prodelio/modules/tasks/types/Status.enum';
import { EditTaskRequest } from '@prodelio/modules/tasks/features/edit-task/request/EditTask.request';
import { ReactElement, SyntheticEvent } from 'react';
import { store } from '@prodelio/config/state/store';

import '@prodelio/components/task/Task.component.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { EditIcon } from '@chakra-ui/icons';
import { useEventDispatch } from '@prodelio/event-system/hooks/useEventDispatch';
import { TaskEvents } from '@prodelio/modules/tasks/Task.events';
import { TimeTrackerFeature } from '@prodelio/modules/tasks/time-tracker/TimeTracker.feature';
import {
  Avatar,
  AvatarSize,
} from '@prodelio/components/avatar/Avatar.component';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';

export interface TaskProps {
  id: string;
  name: string;
  priority: number;
  dueDate: Date | null;
  timeEstimated: number;
  timeTracked: number;
  tags: string[];
  status: StatusEnum;
  titleAction?: JSX.Element;
  actions?: ReactElement;
  assignedTo?: string | null;
}

export const getDueDateModifier = (date: Date): string => {
  const today = startOfToday();
  if (isBefore(date, today)) {
    return 'late';
  } else if (isSameDay(date, today)) {
    return 'same-day';
  } else {
    return 'future';
  }
};

export const getPriorityModifier = (priority: number): string => {
  const modifiers: { [key: number]: string } = {
    1: 'error',
    2: 'alert',
    3: 'warning',
    4: 'success',
    5: 'info',
    6: 'text',
  };

  return modifiers[priority];
};

const transformMillisecondsToHours = (milliseconds: number) => {
  const hours = milliseconds / 1000 / 60 / 60;

  return Math.round(hours * 100) / 100;
};

export const TaskTags: { [key: string]: string } = {
  next_actions: 'Next Actions',
  maybe: 'Maybe',
};

export const TaskComponent = ({
  id,
  actions,
  titleAction,
  assignedTo,
  ...taskData
}: TaskProps) => {
  const { name, priority, dueDate, timeEstimated, timeTracked, status, tags } =
    taskData;
  const dueDateModifier = dueDate
    ? `task__due-date--${getDueDateModifier(dueDate)}`
    : '';
  const priorityModifier = `task__priority--${getPriorityModifier(priority)}`;

  const editTaskAction = useTaskStore((state) => state.edit);
  const dispatch = useEventDispatch();

  const hasSubTasks =
    useTaskStore(
      (state) =>
        state.tasks.filter(({ parentTaskId }) => parentTaskId === id).length
    ) > 0;

  const handleComplete = () => {
    const editTaskRequest = new EditTaskRequest(id, {
      ...taskData,
      tags: tags.join(';'),
      status: status === StatusEnum.DONE ? StatusEnum.TODO : StatusEnum.DONE,
    });
    editTaskAction(editTaskRequest, {});
  };

  const handleClickCheckbox = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const handleEditTask = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(TaskEvents.OPEN_EDIT_TASK_MODAL, id);
  };

  return (
    <Link to={`/app/tasks/${id}`} onClick={handleEditTask}>
      <Box className="task">
        <Box
          as="span"
          onClick={handleClickCheckbox}
          width={'24px'}
          height={'24px'}
        >
          <Checkbox
            iconSize="60px"
            className="task__checkbox"
            defaultChecked={status === StatusEnum.DONE}
            onChange={handleComplete}
          />
        </Box>
        <Heading className="task__header" as="h4">
          <HStack as="span" display="inline-flex">
            <HStack as="span" className="task__header__title" gap={2}>
              <Text>{name}</Text>
              {hasSubTasks && (
                <FontAwesomeIcon icon={solid('bars-staggered')} />
              )}
            </HStack>
            <Box className="task__header__button">
              <IconButton
                onClick={handleEditTask}
                variant="icon"
                size="sm"
                aria-label="Edit task"
                icon={<EditIcon />}
              />
            </Box>
          </HStack>
        </Heading>
        <HStack className="task__info" gap="15px">
          {dueDate && (
            <Text className={`task__due-date ${dueDateModifier}`}>
              {format(dueDate, 'd LLL')}
            </Text>
          )}
          {priority && (
            <Text className={`task__priority ${priorityModifier}`}>
              {PriorityConverter[priority]}
            </Text>
          )}
          <Text className={`task__tags`}>{tags && TaskTags[tags[0]]}</Text>
        </HStack>
        <HStack
          className="task__time-tracker"
          ml="auto"
          gap={12}
          alignItems="center"
        >
          <HStack gap={5}>
            {assignedTo && (
              <Avatar src={null} name={assignedTo} size={AvatarSize.SM} />
            )}
            <HStack className="task__time-tracker__time">
              <span>{transformMillisecondsToHours(timeTracked)} h</span>
              <span> / </span>
              <span>{transformMillisecondsToHours(timeEstimated)} h</span>
            </HStack>
            <TimeTrackerFeature id={id} />
          </HStack>
        </HStack>
      </Box>
    </Link>
  );
};
