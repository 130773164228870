import { Box, Button } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { SyntheticEvent, useState } from 'react';
import { useValidation } from '@prodelio/hooks/form/useValidation';
import { useForm } from '@prodelio/hooks/form/useForm';
import { useParams } from 'react-router-dom';
import { store } from '@prodelio/config/state/store';
import { CreateTaskInput } from '@prodelio/components/create-task-input/CreateTaskInput.component';
import { CreateTaskRequest } from '@prodelio/modules/tasks/features/create-task/requests/CreateTask.request';
import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';

interface CreateInlineTaskProps {
  dueDate?: Date;
  project?: string | null;
}

export const CreateInlineTask = ({
  dueDate,
  project,
}: CreateInlineTaskProps) => {
  const { taskId, projectId } = useParams();
  const {
    state: { modalTaskId, date, tags },
  } = useRouteProps();

  const [showInlineTaskInput, setShowInlineInput] = useState<boolean>(false);
  const { formValues, onChange, resetForm } = useForm(
    {
      title: {
        value: '',
      },
    },
    true
  );
  const { validate } = useValidation({
    title: {
      validators: ['empty'],
    },
  });

  const createTaskAction = useTaskStore((state) => state.create);

  const handleShowInlineInput = () => {
    setShowInlineInput(true);
  };

  const handleHideInlineInput = () => {
    resetForm();
    setShowInlineInput(false);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!validate(formValues)) {
      return;
    }

    let taskDueDate = null;
    if (!modalTaskId) {
      taskDueDate = dueDate ?? date;
    }

    const taskProject = projectId ?? project;
    const request = new CreateTaskRequest({
      title: formValues.title.value,
      projectId: taskProject ?? null,
      parentTaskId: taskId ?? modalTaskId,
      dueDate: taskDueDate,
      priority: 0,
      timeEstimation: 0,
      timeTracked: 0,
      tags: tags,
    });
    createTaskAction(request, {});
    resetForm();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {showInlineTaskInput && (
          <Box mb="15px">
            <CreateTaskInput
              value={formValues.title.value}
              onBlur={handleHideInlineInput}
              onChange={onChange}
              name="title"
            />
          </Box>
        )}
        <Button
          justifyContent="start"
          px={0}
          py="6px"
          variant="ghost-primary"
          onClick={handleShowInlineInput}
          height="auto"
          width="100%"
        >
          <AddIcon sx={{ color: 'accent.default', mr: '15px' }} />
          Create Task
        </Button>
      </form>
    </>
  );
};
