import React, { useCallback, useMemo, useReducer, useRef } from 'react';
import {
  EventCallback,
  EventEmitterContext,
} from '@prodelio/event-system/EventEmitterContext';

interface State {
  [key: string]: EventCallback[];
}

interface Action {
  type: string;
  event: string;
  callback: EventCallback;
}

interface Props {
  children: JSX.Element | JSX.Element[];
}

const reducer = (state: State, action: Action) => {
  const { type, event, callback } = action;
  switch (type) {
    case 'subscribe': {
      if (!state[event]) {
        return { ...state, [event]: [callback] };
      }

      if (state[event].includes(callback)) {
        return state;
      }

      return { ...state, [event]: [...state[event], callback] };
    }
    case 'unsubscribe': {
      if (!state[event] || !state[event].includes(callback)) {
        return state;
      }

      return { ...state, [event]: state[event].filter((c) => c !== callback) };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

export const EventEmitter = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, {});

  const subscribersRef = useRef<State>({});

  subscribersRef.current = useMemo(() => state, [state]);

  const subscribe = useCallback(
    (event: string, callback: EventCallback) => {
      dispatch({
        type: 'subscribe',
        event,
        callback,
      });
    },
    [dispatch]
  );

  const unsubscribe = useCallback(
    (event: string, callback: EventCallback) => {
      dispatch({
        type: 'unsubscribe',
        event,
        callback,
      });
    },
    [dispatch]
  );

  const dispatchEvent = useCallback(
    (event: string, payload: any) => {
      if (subscribersRef.current[event]) {
        subscribersRef.current[event].forEach((callback: EventCallback) => {
          callback(payload);
        });
      }
    },
    [subscribersRef]
  );

  const eventEmitterValues = useMemo(
    () => ({
      subscribe,
      unsubscribe,
      dispatchEvent,
    }),
    [subscribe, unsubscribe, dispatchEvent]
  );

  return (
    <EventEmitterContext.Provider value={eventEmitterValues}>
      {children}
    </EventEmitterContext.Provider>
  );
};
