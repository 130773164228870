import { Box } from '@chakra-ui/react';
import { useEventDispatch } from '@prodelio/event-system/hooks/useEventDispatch';
import { ProjectEvents } from '@prodelio/modules/projects/Project.events';
import { store } from '@prodelio/config/state/store';

interface DeleteProjectProps {
  id: string;
}

export const ArchiveProject = ({ id }: DeleteProjectProps) => {
  const archiveProject = store((state) => state.archiveProject);

  const dispatch = useEventDispatch();

  const handleArchive = () => {
    archiveProject(id);

    dispatch(ProjectEvents.ARCHIVED_PROJECT, id);
  };

  return (
    <>
      <Box w="100%" h="100%" onClick={handleArchive}>
        Archive
      </Box>
    </>
  );
};
