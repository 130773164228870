import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ModalComponent } from '@prodelio/components/modal/Modal.component';
import { useValidation } from '@prodelio/hooks/form/useValidation';
import { useForm } from '@prodelio/hooks/form/useForm';
import { EditProjectRequest } from '@prodelio/modules/projects/features/edit-project/request/EditProject.request';
import { useEventDispatch } from '@prodelio/event-system/hooks/useEventDispatch';
import { ProjectEvents } from '@prodelio/modules/projects/Project.events';
import { store } from '@prodelio/config/state/store';
import { ISharedWith } from '@prodelio/modules/projects/types/ISharedWith';
import { Select } from '@prodelio/components/form/select/Select';

interface EditTaskProps {
  id: string;
  title: string;
  sharedWith: ISharedWith[];
  userId: string;
}

export const EditProject = ({
  id,
  title,
  sharedWith,
  userId,
}: EditTaskProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { formValues, onChange, resetForm } = useForm(
    {
      title: {
        value: title,
      },
      sharedWith: {
        value: sharedWith
          .filter((userRole) => userRole.userId !== userId)
          .map(({ email }) => email)
          .join(','),
      },
    },
    true
  );
  const { formErrors, validate, resetFormErrors } = useValidation({
    title: {
      validators: ['length', 'empty'],
    },
    sharedWith: {
      validators: [],
    },
  });

  const editProject = store((state) => state.editProject);

  const dispatch = useEventDispatch();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    resetForm();
    resetFormErrors();
    setOpenModal(false);
  };

  const handleSubmit = () => {
    if (!validate(formValues)) {
      return;
    }

    setIsLoading(true);

    const editProjectRequest = new EditProjectRequest(id);
    const dataRequest = {
      title: formValues.title.value,
      sharedWith: formValues.sharedWith.value,
    };
    editProjectRequest.body = dataRequest;
    editProject(id, editProjectRequest);

    dispatch(ProjectEvents.EDITED_PROJECT);

    setIsLoading(false);
    handleCloseModal();
  };

  const renderActions = () => (
    <HStack spacing="20px">
      <Button
        isLoading={isLoading}
        onClick={handleSubmit}
        variant="primary"
        loadingText="Editing..."
      >
        Edit
      </Button>
      <Button variant="secondary" onClick={handleCloseModal}>
        Cancel
      </Button>
    </HStack>
  );

  return (
    <>
      <Box w="100%" h="100%" onClick={handleOpenModal}>
        Edit
      </Box>
      <ModalComponent
        open={openModal}
        onClose={handleCloseModal}
        title="Edit project"
        actions={renderActions()}
      >
        <VStack spacing={6} alignItems="left">
          <FormControl isInvalid={!!formErrors.title}>
            <FormLabel>Task name</FormLabel>
            <Input
              variant="filled"
              type="text"
              name="title"
              value={formValues.title.value}
              onChange={onChange}
            />
            {formErrors.title && (
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!formErrors.sharedWith}>
            <FormLabel>Shared with</FormLabel>
            <Select
              name="sharedWith"
              selected={
                formValues.sharedWith.value
                  ? formValues.sharedWith.value.split(',')
                  : []
              }
              onChange={onChange}
              options={[
                ...formValues.sharedWith.value
                  .split(',')
                  .map((val: string) => ({
                    value: val,
                    label: val,
                  })),
              ]}
              multi
              writable
              writableLabel="Shared with "
            />

            {formErrors.sharedWith && (
              <FormErrorMessage>{formErrors.sharedWith}</FormErrorMessage>
            )}
          </FormControl>
        </VStack>
      </ModalComponent>
    </>
  );
};
