import { Request } from '@prodelio/hooks/api/Request.class';
import { apiWrapper } from '@prodelio/config/api/api';
import { toastError } from '@prodelio/config/toast/toast';
import { Project } from '@prodelio/config/state/projects/Project.interface';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';

export const editProject = (
  set: any,
  get: any,
  projectId: string,
  request: Request
) => {
  const updatedAt = new Date().toISOString();
  apiWrapper(request).catch((e: any) => {
    const syncProjects = get().syncProjects;
    set(() => ({
      syncProjects: [
        ...syncProjects,
        {
          id: projectId,
          ...request.body,
          updatedAt,
          syncStatus: SyncStatus.EDITED,
        },
      ],
    }));
    toastError('Error when editing the project.', e.error);
  });

  const projects = get().projects;
  set(() => ({
    projects: projects.map((project: Project) => {
      if (project.id === projectId) {
        return {
          ...project,
          ...request.body,
          sharedWith: request.body?.sharedWith
            ?.split(',')
            .map((email: string) => ({ userId: email, email })),
          updatedAt,
        };
      }

      return project;
    }),
  }));
};

export type EditProjectType = (projectId: string, request: Request) => void;
