import { Box } from '@chakra-ui/react';
import { useEventDispatch } from '@prodelio/event-system/hooks/useEventDispatch';
import { ProjectEvents } from '@prodelio/modules/projects/Project.events';
import { store } from '@prodelio/config/state/store';

interface DeleteProjectProps {
  id: string;
}

export const DeleteProject = ({ id }: DeleteProjectProps) => {
  const deleteProject = store((state) => state.deleteProject);

  const dispatch = useEventDispatch();

  const handleDelete = () => {
    deleteProject(id);

    dispatch(ProjectEvents.DELETED_PROJECT, id);
  };

  return (
    <>
      <Box w="100%" h="100%" onClick={handleDelete}>
        Delete
      </Box>
    </>
  );
};
