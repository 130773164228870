import { useEffect, useReducer } from 'react';
import { api } from '@prodelio/config/api/api';
import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiErrorClass } from '@prodelio/hooks/api/ApiError.class';
import { AxiosError } from 'axios';

export enum Status {
  WAITING = 'WAITING',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  DONE = 'DONE',
}

interface State {
  status: Status;
  data: any;
}

interface Action {
  type: Status;
  payload?: any;
}

const initialState: State = {
  status: Status.WAITING,
  data: undefined,
};

interface ActionsReducer {
  [key: string]: () => State;
}

const reducer = (state: State, action: Action): State => {
  const actions: ActionsReducer = {
    [Status.LOADING]: () => ({
      ...state,
      status: Status.LOADING,
    }),
    [Status.ERROR]: () => ({
      ...state,
      status: Status.ERROR,
      data: action.payload.data,
    }),
    [Status.DONE]: () => ({
      ...state,
      status: Status.DONE,
      data: action.payload.data,
    }),
  };

  return actions[action.type]();
};

export interface useApiReturn<T> extends State {
  fetch: () => Promise<any>;
  data: T;
}

export const useApi = <T>(req: Request, autoFetch = true): useApiReturn<T> => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetch = async () => {
    dispatch({
      type: Status.LOADING,
    });

    try {
      const data = await api({
        method: req.method,
        url: req.url,
        data: req.body || null,
      });
      dispatch({
        type: Status.DONE,
        payload: data,
      });

      return data;
    } catch (e: unknown) {
      if (e instanceof AxiosError && e.response) {
        const { error, data } = e.response.data;

        dispatch({
          type: Status.ERROR,
          payload: error,
        });

        throw new ApiErrorClass(e.response.status, error, data);
      }

      throw new ApiErrorClass(null, 'Something went wrong');
    }
  };

  useEffect(() => {
    if (autoFetch) {
      fetch();
    }
  }, [req.url]);

  return {
    ...state,
    fetch,
  };
};
